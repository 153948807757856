import {
  init as sentryInit,
  browserTracingIntegration,
  replayIntegration,
} from "@sentry/remix";
import posthog from "posthog-js";

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});

if (process.env.NODE_ENV === "production") {
  sentryInit({
    dsn: "https://54704028cf6ba3eee583d27e54c540a9@o78232.ingest.us.sentry.io/4507325902553088",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    environment: process.env.NODE_ENV,
    normalizeDepth: 10,

    integrations: [
      browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      replayIntegration(),
    ],
  });

  posthog.init("phc_sZrstvidEulo2eKxsOOLY39pLzI0f51G0HC4tzDwW9D", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
  });
}
